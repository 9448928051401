import request from '@/utils/request';
// 获取值集列表
export function getValueDataList(id, params, pageNum = 1, pageSize = 0) {
    const data = {
        id,
        params,
        pageNum,
        pageSize
    };
    if (!id) {
        return
    }
    return request({
      url: '/web/core/api/v1/componentV2/queryValsetData',
        method: 'post',
        data
    });
}

export function getValueDataList2(id, params) {
    const data = {
        id,
        params,
    };
    if (!id) {
        return
    }
    return request({
      url: '/web/core/api/v1/componentV2/queryValsetData',
        method: 'post',
        data
    });
}




export function refreshMapper() {
    return request({
        url: '/web/core/api/v1/component/refreshMapper',
        method: 'get'
    });
}

export function refreshJob() {
    return request({
        url: '/web/core/api/v1/job/refresh/all',
        method: 'get'
    });
}

/**
 * 导出数据
 * @param {*} tableId 表记录ID
 */
export function exportExcel(tableId, params) {
    const data = {
        tableId,
        params
    };
    return request({
        url: '/web/core/api/v1/component/excel/exportExcel',
        method: 'post',
        data: data,
        responseType: 'blob'
    });
}
export function policeExcel( data) {

    return request({
        url: '/web//statistic/policeExcel',
        method: 'post',
        data: data,
        responseType: 'blob'
    });
}

/**
 * 导出模板
 * @param {*} tableId 表记录ID
 */
export function exportTemplateExcel(tableId) {
    return request({
        url: '/web/core/api/v1/component/excel/exportTemplateExcel/' + tableId,
        method: 'get',
        responseType: 'blob'
    });
}

/**
 * 导出模板
 * @param {*} tableId 表记录ID
 */
export function exportTemplateFile(fileId) {
    return request({
        url: '/web/api/v1/file/download?id=' + fileId,
        method: 'get',
        responseType: 'blob'
    });
}


/**
 * 返回七牛云的文件信息
 * @param {*} tableId 表记录ID
 */
export function exportTemplateFileV2(fileId) {
    return request({
        url: '/web/qiniu/file/getFileMetaInfoFileId?fileId=' + fileId,
        method: 'get',
    });
}

export function downloadFile(fileId) {
    return request({
        url: '/web/api/v1/file/download?id=' + fileId,
        method: 'get',
        responseType: 'blob'
    });
}


/**
 * 导入文件上传
 * @param {*} tableId 表记录ID
 * @param {*} formData 上传文件
 */
export function importExcel(tableId, formData) {
    return request({
        url: '/web/core/api/v1/component/excel/importExcel/' + tableId,
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

// 通用保存方法
export function saveOrUpdate(tableId, params) {
    const data = {
        tableId,
        params
    };
    return request({
        url: '/web/core/api/v1/component/saveOrUpdate',
        method: 'post',
        data
    });
}

export function deleteById(tableId, id) {
    const data = {
        tableId,
        id
    };
    return request({
        url: '/web/core/api/v1/component/deleteById',
        method: 'post',
        data
    });
}


export function deleteByIds(tableId, ids) {
    const data = {
        tableId,
        ids
    };
    return request({
        url: '/web/core/api/v1/component/deleteByIds',
        method: 'post',
        data
    });
}

export function deleteJobById(id) {
    return request({
        url: '/web/core/api/v1/job/deleteJobById/' + id,
        method: 'get'
    });
}

// 独立新增必到点类型
export function addLabel(data) {
    return request({
        url: '/web/intell/model/point/addLabel',
        method: 'post',
        data
    });
}

export function closeJobById(id) {
    return request({
        url: '/web/core/api/v1/job/closeJob/' + id,
        method: 'get'
    });
}

export function openJobById(id) {
    return request({
        url: '/web/core/api/v1/job/refresh/' + id,
        method: 'get'
    });
}

export function testJobById(id) {
    return request({
        url: '/web/core/api/v1/job/testJob/' + id,
        method: 'get'
    });
}

export function getInfoById(tableId, id) {
    const data = {
        tableId,
        id
    };
    return request({
        url: '/web/core/api/v1/component/deleteById',
        method: 'post',
        data
    });
}

/**
 * 导入文件上传
 * @param {*} formData 上传文件
 */
export function uploadTest(formData) {
    return request({
        url: '/web/core/api/v1/command/uploadFile',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

/**
 * 获取表单配置信息
 * @param {*} formId
 */
export function getFormDefById(formId) {
    const data = {
        id: formId
    };
    return request({
        url: '/web/core/api/v1/component/getFormDefById',
        method: 'post',
        data
    });
}

/**
 * 获取表格配置信息
 * @param {}} tableId
 */
export function getTableDefById(tableId) {
    const data = {
        id: tableId
    };
    return request({
        url: '/web/core/api/v1/component/getTableDefById',
        method: 'post',
        data
    });
}

/**
 * 获取界面配置信息
 * @param {*} pageId
 */
export function getPageDefById(pageId) {
    const data = {
        id: pageId
    };
    return request({
        url: '/web/core/api/v1/component/getPageDefById',
        method: 'post',
        data
    });
}


/**
 * 所属辖区
 * @param {*} data
 */
export function queryGridData() {
    let data = {
        id: "100",
        pageNum: 1,
        pageSize: 0,
        params: {}
    };
    return request({
      url: '/web/core/api/v1/componentV2/queryValsetData',
        method: 'post',
        data
    });
}

export function queryEchartData(sid, params) {
    const data = {
        sid,
        params
    };
    return request({
        url: '/web/core/api/v1/grid/queryEchartData',
        method: 'post',
        data
    });
}

export function queryGridDataV2(data) {
  return request({
    url: '/web/core/api/v2/grid/queryGridDataV2',
    method: 'POST',
    data
  });
}

export function getStatisticsInfo(data) {
  return request({
    url: '/web/IllegalReport/statistics',
    method: 'POST',
    data
  });
}


export function getUploadToken() {
  return request({
    url: '/web/qiniu/file/token',
    method: 'get'
  });
}

export function saveFileMeta(data) {
  return request({
    url: '/web/qiniu/file/meta',
    method: 'post',
    data
  });
}

export function uploadFile(imgData) {
  return request({
    url: 'https://upload.qiniup.com',
    method: 'post',
    data: imgData
  });
}
