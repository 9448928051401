<template>
  <treeselect
    :id="name"
    :ref="name"
    v-model="modelValue"
    :name="name"
    :multiple="multiple"
    :options="groupData"
    :placeholder="phdrValue"
    :clearable="clearable"
    :disabled="disabled"
    @open="open"
    @close="close"
    @input="input"
    @select="select"
    @deselect="deselect"
    @clear="clear"
    @search-change="searchChange"
  >
    <p
      style="
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 90%;
      "
      slot="option-label"
      slot-scope="{ node }"
      :title="node.label"
    >
      <template> {{ node.label }}</template>
    </p>
  </treeselect>
</template>

<script>
import { props } from './TTreeSelectProps';
import { getValueDataList } from '@/api/component';
import TBaseGroup from '@/components/trina/base/TBaseGroup';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'TTreeSelect',
  extends: TBaseGroup,
  components: {
    Treeselect,
  },
  props: props,

  data() {
    console.log(this.$props, 'this.$props');

    return {
      modelValue: null,
      phdrValue: '请选择辖区',
    };
  },
  created() {
    // 是否默认选择当前辖区
    if (this.$props.SetDeptId) {
      this.modelValue = sessionStorage.getItem('customViewDeptId');//改为新的
    } else {
      this.modelValue = this.$props.defaultValue
    }


    if (this.$route.query.type == 'overview') {
      if (this.$route.query.province == '上海市') {
        this.phdrValue = '闵行区';
      } else if (this.$route.query.province == '山东省') {
        this.phdrValue = '淄博市';
      } else if (this.$route.query.province == '四川省') {
        this.phdrValue = '成都温江辖区';
      } else if (this.$route.query.province == '江西省') {
        this.phdrValue = '景德镇公安局';
      }
    }
  },
  mounted() {
    console.log(this.$refs.TTreeSelect, '1111111111111111111111');
  },
  methods: {
    setSelectValue() {
      if (this.defaultValue) {
        this.modelValue = this.defaultValue;
      }
    },
    getValueDataList() {
      if (!this.valsetId ) {
        return;
      }
      getValueDataList(this.valsetId, this.queryParam)
        .then((response) => {
          const responseData = response.data;
          if (responseData) {
            this.groupData = responseData.list[0];
            this.total = responseData.total ? responseData.total : 0;
            this.setSelectValue();
            // 没有默认值，autoSelected=true
            if (
              !this.defaultValue &&
              this.autoSelected &&
              this.groupData &&
              this.groupData.length > 0
            ) {
              this.modelValue = this.groupData[0].id;
            }
            if (this.modelValue!==this.groupData[0].id){
              this.modelValue = this.groupData[0].id;
            }
          }
        })
        .catch((error) => {
          console.log('Get valueSet data failed.', error);
          this.groupData = [];
          this.total = 0;
        });
    },
    open() {
      this.$emit('open');
    },
    close(value, instanceId) {
      this.$emit('close', value);
    },
    input(value, instanceId) {
      this.$emit('input', value, instanceId);
    },
    select(node, instanceId) {
      this.$emit('select', this.name, node.id, node, instanceId);
    },
    deselect(node, instanceId) {
      this.$emit('deselect', this.name, node.id, node, instanceId);
    },
    clear(node, instanceId) {
      this.$emit('clear', this.name, node.id, node, instanceId);
    },
    searchChange(searchQuery, instanceId) {
      this.$emit('searchChange', this.name, searchQuery, instanceId);
    },
  },
};
</script>
<style scoped></style>
