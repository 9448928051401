import request from '@/utils/request'
import { method } from 'lodash'

// 获取任务详情
export function getPoliceCirculationDetail(id) {
  return request({
    url: '/web/police/getPoliceCirculationDetail?id=' + id,
    method: 'get'
  })
}export function queryGridDataPoliceSend2(taskUnqId) {
  return request({
    url: '/web/police/queryGridDataPoliceSend2?taskUnqId=' + taskUnqId,
    method: 'get'
  })
}
export function queryGridDataPoliceSend(data){
  return request({
    url: '/web/police/queryGridDataPoliceSend',
    method: 'POST',
    responseType: 'blob',
    data: data
  })
}
export function exportDeptAndUserAll(data){
  return request({
    url: '/web/dept/exportDeptAndUserAll',
    method: 'POST',
    responseType: 'blob',
    data: data
  })
}
// export function queryGridDataPoliceSend(data) {
//   return request({
//     url: '/web/police/queryGridDataPoliceSend',
//     method: 'post',
//     data
//   })
// }
// new获取任务详情
export function getPropertyReportListParticulars(data) {
  return request({
    url: "/app/v1/police/getPropertyReportListParticulars",
    method: 'post',
    data,
  })
}

// 配置反映对象
export function configurationSave(data) {
  return request({
    url: "/web/configuration/save",
    method: 'post',
    data,
  })
}

// 修改反映对象配置
export function configurationUpdate(data) {
  return request({
    url: "/web/configuration/update",
    method: 'post',
    data,
  })
}


export function getQuicklyIssuedToNearby(id) {
  return request({
    url: "app/v1/police/getQuicklyIssuedToNearby/" + id ,
    method: 'get',
  })
}

// 结束情报
export function updateXyPropertyEndCirculationHasRead(data) {
  return request({
    url: "/app/v1/police/updateXyPropertyEndCirculationHasRead/"+ data.withoutRepetitionId + "/"+ data.handleRemark,
    method: "get",
  });
  }
//权限按钮
export function getPropertyRole(data) {
  return request({
    url: "app/v1/police/getPropertyRole",
    method: "post",
    data,
  });
}
export function getPropertyRoles(data) {
  return request({
    url: "app/v1/police/getPropertyRoles",
    method: "post",
    data,
  });
}

// 驳回情报
export function rejectTaskPropertys(data) {
  console.log(data);
  return request({
    url: "app/v1/police/rejectTaskPropertys/" + data.withoutRepetitionId + "/"+ data.handleRemark,
    method: "get",
  });
}
// 获取移交人
export function getCurrentUserDeptIsColleague(data) {
  return request({
    url: "app/v1/police/getCurrentUserDeptIsColleague",
    method: "post",
    data,
  });
}
export function getReflectDetail(data) {
  return request({
    url: "/app/v1/police/getReflectDetail/"+data,
    method: "get",
  });
}

export function getCurrentUserDeptIsColleague2(data) {
  return request({
    url: "app/v1/police/getCurrentUserDeptIsColleague2",
    method: "post",
    data,
  });
}
//提交移交人
export function handOverTaskPropertys(data) {
  return request({
    url:
      "app/v1/police/handOverTaskPropertys/" + data.withoutRepetitionId + "/" + data.reflectDeptId + "/" + data.handleRemark,
    method: "get",
  });
}
// 删除反映对象配置
export function configurationDelete(data) {
  return request({
    url: "/web/configuration/delete",
    method: 'post',
    data,
  })
}
// 情报待办新增
export function saveInformations(data) {
  return request({
    url: "/web/informations/saveInformations",
    method: 'post',
    data,
  })
}
// 情报待办修改
export function updateInformations(data) {
  return request({
    url: "/web/informations/updateInformations",
    method: 'post',
    data,
  })
}
// 情报待办批量删除
export function informationsDeleteBach(data) {
  return request({
    url: "/web/remove/informations",
    method: 'post',
    data,
  })
}
export function disputeDetailDeleteIds(data) {
  return request({
    url: "/web/dispute/disputeDetailDeleteIds",
    method: 'post',
    data,
  })
}
export function exportListAll(data) {
  return request({
    url: "/web/dispute/exportListAll",
    method: 'post',
    responseType: 'blob',
    data,
  })
}

export function exportListAllV2(data) {
  return request({
    url: "/web/dispute/getUserPointsListExport",
    method: 'post',
    responseType: 'blob',
    data,
  })
}

//纠纷预约权限查询
export function getDisputeAuthByDeptId(deptId) {
  return request({
    url: '/web/dispute/getDisputeAuthByDeptId',
    method: 'get',
    params:{deptId}
  })
}

//纠纷预约权限保存
export function saveDisputeAuth(data) {
  return request({
    url: '/web/dispute/saveDisputeAuth',
    method: 'post',
    data
  })
}

//纠纷预约配置查询
export function getDisputeConfigByDeptId(deptId) {
  return request({
    url: '/web/dispute/getDisputeConfigByDeptId',
    method: 'get',
    params:{deptId}
  })
}

//消息通知配置查询
export function getNotificationShowDept(deptId) {
  return request({
    url: '/web/merchant/getNotificationShowDept',
    method: 'get',
    params:{deptId}
  })
}

// 总览管理渲染-消息通知--编辑操作
export function saveNotificationShowDept(data) {
  return request({
    url: '/web/merchant/saveNotificationShowDept',
    method: 'post',
    data,
  });
}
export function copyNotificationShowDept(data) {
  return request({
    url: '/web/merchant/copyNotificationShowDept',
    method: 'post',
    data,
  });
}

//纠纷预约配置保存
export function saveDisputeConfig(data) {
  return request({
    url: '/web/dispute/saveDisputeConfig',
    method: 'post',
    data
  })
}

//纠纷预约配置保存
export function batchSaveDisputeConfig(data) {
  return request({
    url: '/web/dispute/batchSaveDisputeConfig',
    method: 'post',
    data
  })
}

// 情报待办批量删除
export function listInformations(data) {
  return request({
    url: "/web/informations/listInformations",
    method: 'post',
    data,
  })
}
//情报待办删除
export function coninDelete(id) {
  return request({
    url: '/web/informations/delete?id=' + id,
    method: 'get'
  })
}
//情报待办删除
export function getDisputeDetail(id) {
  return request({
    url: '/web/dispute/getDisputeDetail/' + id,
    method: 'get'
  })
}
export function deleteDisputeDetail(id) {
  return request({
    url: '/web/dispute/deleteDisputeDetail/' + id,
    method: 'get'
  })
}
// 处理预约
export function handleDispute(data) {
  return request({
    url: "/app/dispute/handleDispute",
    method: "post",
    data
  });
}

// 批量删除反映对象配置
export function configurationDeleteList(data) {
  return request({
    url: "/web/configuration/deleteList",
    method: 'post',
    data,
  })
}
export function deleteList(data) {
  return request({
    url: 'web/police/deleteList',
    method: 'post',
    data
  });
}
// 批量删除反映对象配置
export function deleteTypeList(data) {
  return request({
    url: "/web/police/deleteTypeList",
    method: 'post',
    data,
  })
}


export function getPropertyCirculationDetail(id) {
  return request({
    url: '/web/police/getPropertyCirculationDetail?id=' + id,
    method: 'get'
  })
}
export function getApproversDetail(id) {
  return request({
    url: '/app/v1/police/getApproversDetail?id=' + id,
    method: 'get'
  })
}
export function getReadText(id) {
  return request({
    url: '/web/ping/readText/' + id,
    method: 'get'
  })
}

export function deletePoliceTask(id) {
  return request({
    url: '/web/police/deletePoliceTask?id=' + id,
    method: 'get'
  })
}

//批量删除
export function batchDeletePoliceTask(data) {
  return request({
    url: '/web/police/batchDeletePoliceTask',
    method: 'POST',
    data
  })
}

export function deletePropertyTask(id) {
  return request({
    url: '/web/police/deletePropertyTask?id=' + id,
    method: 'get'
  })
}

export function batchDeletePropertyTask(data) {
  return request({
    url: '/web/police/batchDeletePropertyTask',
    method: 'POST',
    data
  })
}

// 警务端-任务下发
export function policePublishTask(data) {
  return request({
    url: 'app/v1/police/publishTask',
    method: 'post',
    data
  });
}
//获取警情流转下发任务辖区数据
export function getDeptInformationId(data) {
  return request({
    url: "/app/v1/dept/getDeptInformationId?deptId=" + data,
    method: "get"
  });
}
//获取警情流转下发任务辖区数据
export function getEventTypeDetail(data) {
  return request({
    url: "/web/police/getEventTypeDetail/" + data,
    method: "get"
  });
}
//批量修改事件类型
export function batchUpdateEventType(data) {
  return request({
    url: "/web/police/batchUpdateEventType" ,
    method: "post",
    data
  });
}

export function getDeptUserLists(deptId,type) {
  return request({
    url: 'app/v1/dept/getDeptUserLists/' + deptId +'/'+ type,
    method: 'get'
  });
}
// 获取子部门
export function deptList(data) {
  return request({
    url: 'app/v1/dept/getDeptUserList/'+data,
    method: 'get'
  })
}
// 获取子部门每个部门的巡更人员
export function deptUserList(data) {
  return request({
    url: '/app/v1/dept/getDeptUserList/'+data,
    method: 'get'
  })
}

// 获取部门巡更人员
export function getDeptUserListsV2(data) {
  return request({
    url: '/app/v1/dept/getDeptUserListsV2',
    method: 'post',
    data
  })
}



export function getLabelListByType(data) {
  return request({
    url:'/web/label/getLabelListByType',
    method:'post',
    data
  })
}
export function getDictListByDictType() {
  return request({
    url:'/mini/v1/dict/getDictListByDictType/user_grade',
    method:'get'
  })
}
//警务端-结束任务
export function finishTask(taskId,status) {
  // return request({
  //   url: 'app/v1/police/finishTask/'+taskId,
  //   method: 'get'
  // });
  return request({
    url: "/web/policeTask/updatePoliceCirculation",
    method: "post",
    data: {
      id: taskId,
      status: status,
    }
  });
}

//物业端-结束任务
export function finishTaskProperty(taskId) {
  return request({
    url: 'app/v1/police/finishTaskProperty/'+taskId,
    method: 'get'
  });
}
// 警务下发
export function issuedTask(data) {
  console.log("issuedTask", JSON.stringify(data, null, "\t"));
  return request({
    url: "app/v1/police/issuedTask",
    method: "post",
    data
  });
}
export function getPoliceEventList(deptId) {
  return request({
    url: "/web/label/queryPoliceTaskLabelList?sysDeptId=" + deptId,
    method: "get"
  });
}//获取接受任务人员
export function taskRecipient(data) {
  return request({
    url: "/app/v1/police/taskRecipientTwo",
    method: "get",
    params: data
  });
}
//获取个人任务反馈信息
export function getIssuerFeedbackList(taskId,userId,receiveId) {
  return request({
    url: "app/v1/police/getIssuerFeedbackListTwo/" + taskId+"/"+ userId + "/" + receiveId   ,
    method: "get"
  });
}
//获取技能标签
export function getNearPoliceList(
  deptId,
  num,
  position,
  titleList,
  equipList,
  skillList
) {
  const data = {
    sysDeptId: deptId,
    quantity: num,
    longitude: position.longitude,
    latitude: position.latitude,
    distance: 0,
    equipmentLabelIds: equipList,
    skillLabelIds: skillList,
    userGradeList: titleList
  };
  return request({
    url: "/web/policeTask/getPoliceTaskNearUser",
    method: "post",
    data
  });
}
export function signAndFeedbackTaskTwo(data) {
  return request({
    url: "app/v1/police/signAndFeedbackTaskTwo",
    method: "post",
    data
  });
}

export function saveTaskAppraise(data) {
  return request({
    url: "app/v1/police/saveTaskAppraiseTwo",
    method: "post",
    data
  });
}
export function signAndFeedbackTaskProperty(data) {
  return request({
    url: "app/v1/police/signAndFeedbackTaskProperty",
    method: "post",
    data
  });
}
// 情报签收
export function updateXyPropertyCirculationHasRead(withoutRepetitionId) {
  return request({
    url: "app/v1/police/updateXyPropertyCirculationHasRead",
    method: "post",
    params: {
      withoutRepetitionId: withoutRepetitionId
    }
  });
}
//获取下发至附近的经纬度坐标点
export function getSoSList(data) {
  return request({
    url: "/app/v1/police/getSoSList",
    method: "get",
    params: data
  });
}

export function getTaskByDeptCodeAndStatus(data){
  return request({
    url: "/app/v1/police/getTaskByDeptCodeAndStatus",
    method: "post",
    data
  });
}

export function getReceiveUserIdsByTaskType(data){
  return request({
    url: "/app/v1/police/getReceiveUserIdsByTaskType?taskType=" + data,
    method: "get",
  });
}

//获取有效情报统计数据
export function getEffectiveReports(data){
  return request({
    url: "/web/police/effectiveReports",
    method:"post",
    data
  });
}

// 标记or取消标记方法
export function updateTaskStatus(withoutRepetitionId){
  return request({
    url: "/app/v1/police/updateTaskStatus/" + withoutRepetitionId,
    method: "post",
  });
}



export function getcolumns() {
  return request({
    url: "/web/columns/query",
    method: "get"
  });
}


export function getDeptUserListsV3(data) {
  return request({
    url: "/app/v1/dept/getDeptUserListsV3",
    method: "post",
    data
  });
}


// 获取列表姓名
export function getUserNameOptions(data) {
  return request({
    url: "/app/v1/dept/getUserNameOptions",
    method: "post",
    data
  });
}
// 获取列表手机号
export function getPhoneOptions(data) {
  return request({
    url: "/app/v1/dept/getPhoneOptions",
    method: "post",
    data
  });
}


// 技能
export function getSkillOptions(data) {
  return request({
    url: "/app/v1/dept/getSkillOptions",
    method: "post",
    data
  });
}

// 装备
export function getEquipmentOptions(data) {
  return request({
    url: "/app/v1/dept/getEquipmentOptions",
    method: "post",
    data
  });
}

// 人员类型
export function getUserGradeOptions(data) {
  return request({
    url: "/app/v1/dept/getUserGradeOptions",
    method: "post",
    data
  });
}

// 用户身份
export function getUserCategoryOptions(data) {
  return request({
    url: "/app/v1/dept/getUserCategoryOptions",
    method: "post",
    data
  });
}

// 任务详情按人查看列表
export function taskByUserPerformance(data) {
  return request({
    url: "/app/v1/police/taskByUserPerformance",
    method: "post",
    data
  });
}

// 任务详情按辖区查看列表

export function taskByDeptNamePerformance(data) {
  return request({
    url: "/app/v1/police/taskByDeptNamePerformance",
    method: "post",
    data
  });
}


export function taskPerformance(id) {
  return request({
    url: "/app/v1/police/taskPerformance?taskUniqId=" + id,
    method: "post",
  });
}
