import router from './index'
import store from '@/store';
// import store from './store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
// import { Message } from 'element-ui'
import { getToken } from '@/utils/auth' // 验权
// import { clear } from '@/utils/loading'
import { CODE_OK } from '@/utils/request';
import global_ from '@/Global'
const whiteList = ['/login','/register','/disputer','/survey','/publicity','/formManager'] // 不重定向白名单
router.beforeEach((to, from, next) => {
    NProgress.start()
    // console.log(to.path)
    if (to.path == '/') {
        next({ path: '/login' })
        return;
    } else {
        next()
    }
    /* 登录页初始化数据 */
    if (to.path == '/login') {
        store.commit('SET_ISGETMODULES', false)
            // localStorage.clear();
        Object.keys(localStorage).forEach(v => {
            if (v != 'language') {
                localStorage.removeItem(v)
            }
        })
    }
    // clear()
    if (getToken()) {
        if (to.path === '/login') {
            next()
            NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
        } else {
            store.dispatch('user/getUserInfo').then(responseData => {
                console.log('---getUserInfo---', responseData);
                localStorage.setItem('Authority', responseData.expandMap.qperationAuthority)

                if(responseData.expandMap.lon){
                  let pop = [responseData.expandMap.lon,responseData.expandMap.lat]
                  localStorage.setItem('DeptCenter', pop)
                }else{
                  localStorage.removeItem('DeptCenter')
                }
                if(responseData.expandMap.customViewDeptId){
                    sessionStorage.setItem('customViewDeptId', responseData.expandMap.customViewDeptId);
                }
                if(responseData.expandMap.customViewDeptIds){
                    sessionStorage.setItem('customViewDeptIds', responseData.expandMap.customViewDeptIds);
                }
                if(responseData.expandMap.thisMonthWhetherChangePassword==0){
                    next({ path: '/dashboard' })
                }
                console.log(responseData.expandMap.deptName,);
                if (responseData.expandMap.deptName  == '纪王集镇' ) {
                    global_.title = '华漕镇纪王集镇步（车）巡系统应用场景'
                    console.log( global_.title);
                }else{
                    global_.title = '综合治理一体化智慧指挥平台'
                }

            });
            if (!store.getters.isGetModules) {
                console.log('SetRouter')
                store.dispatch('SetRouter', router).then(res => {
                    if (res.code == 200) {
                        NProgress.done()
                        if (!res.data.length) {
                            setTimeout(function() {
                                store.dispatch('LogOut')
                            }, 4000)
                            return;
                        }
                        router.addRoutes([
                            ...store.getters.vueRouter,
                            {
                                path: '*',
                                redirect: '/dashboard'
                            }
                        ])
                        next(to)
                    } else {
                        Message.error('获取菜单失败')
                        store.dispatch('LogOut')
                    }
                })
            } else {
                next()
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next({ path: '/login' })
                // next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done() // 结束Progress
})
