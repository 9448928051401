import request from '@/utils/request';
import axios from 'axios'
import {getToken} from "@/utils/auth";
// import md5 from 'js-md5';

export function login(data) {
    return request({
        url: 'web/system/login/loginName',
        method: 'post',
        data: data
    })
}

export function getMenu() {
    return request({
        url: '/web/system/resource/resourceTree?name=',
        method: 'get'
    });
}

export function returnResourceTree() {
  return request({
      url: '/web/system/resource/returnResourceTree',
      method: 'get'
  });
}




export function getUserInfo(token) {
    if(!token){
      token = getToken()
    }
    return request({
        url: '/web/system/login/getCurrUserInfo',
        method: 'get',
        params: {
            token
        }
    });
}

export function logout() {
    return request({
        url: '/web/system/login/exit',
        method: 'post'
    });
}
export function updatePageSize(data) {
    return request({
        url: '/web/system/resource/updatePageSize/'+data,
        method: 'post'
    });
}

export function updateWebMsgReminder(data) {
  return request({
    url: '/web/system/user/updateWebMsgReminder/'+data,
    method: 'get'
  });
}export function getWebMsgReminder(data) {
  return request({
    url: '/web/system/user/getWebMsgReminder/'+data,
    method: 'get'
  });
}
export function getPageSize(data) {
    return request({
        url: '/web/system/resource/getPageSize',
        method: 'get'
    });
}

export function loginOld(params) {
    //params.loginPwd = md5(params.loginPwd)
    params.grant_type = "password";
    params.scope = "all";
    // return loginByFormData('/oauth/token', params)

    console.log('login', params)
    let formData = new FormData();
    for (var key in params) {
        formData.append(key, params[key]);
    }
    console.log('login-formData', formData)
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: "/oauth/token",
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': 'Basic eGl5b3U6MTIzNDU2',
                'platform': 'web'
            },
            withCredentials: true,
            data: formData
        }).then((res) => {
            console.log(res);
            resolve(res)
        }, err => {
            reject(err)
        }).catch(error => {
            console.log(error)
            resolve({
                code: 99999,
                msg: '网络出错'
            })
        });
    })
}

export function addIntellUser(data) {
    return request({
        url: '/web/system/user/addIntellUser',
        method: 'post',
        data
    });
}

export function updateIntellUser(data) {
    return request({
        url: '/web/system/user/updateIntellUser',
        method: 'post',
        data
    });
}

export function delIntellUser(userId) {
    return request({
        url: '/web/system/user/delIntellUser/' + userId,
        method: 'get'
    });
}

export function lockIntellUser(userId) {
  return request({
    url: '/web/system/user/lockIntellUser/' + userId,
    method: 'get'
  });
}

export function unlockIntellUser(userId) {
  return request({
    url: '/web/system/user/unlockIntellUser/' + userId,
    method: 'get'
  });
}

export function getUserDetail(userId) {
    return request({
        url: '/web/system/user/userDetail/' + userId,
        method: 'get'
    });
}


export function addAdminUser(data) {
    return request({
        url: '/web/system/user/addAdminUser',
        method: 'post',
        data
    });
}

// 新
export function addAdminUserV2(data) {
  return request({
      url: '/web/system/user/addAdminUserV2',
      method: 'post',
      data
  });
}



export function updateAdminUser(data) {
    return request({
        url: '/web/system/user/updateAdminUser',
        method: 'post',
        data
    });
}


// 新
export function updateAdminUserV2(data) {
  return request({
      url: '/web/system/user/updateAdminUserV2',
      method: 'post',
      data
  });
}



export function delAdminUser(userId) {
    return request({
        url: '/web/system/user/delAdminUser/' + userId,
        method: 'get'
    });
}

export function selectUserByDeptId(deptId) {
    return request({
        url: '/web/system/user/selectUserByDeptId/' + deptId,
        method: 'get'
    });
}


export function selectUserByName(data) {
    return request({
        url: '/web/system/user/selectUserByName',
        method: 'post',
        data
    });
}

export function useAddressBookList(data) {
  return request({
    url: 'app/v1/user/useAddressBookList',
    method: 'post',
    data
  });
}


//批量修改模板下载
export function importEditExcel(data) {
  return request({
    url: '/web/system/user/importEditExcel',
    method: 'post',
    responseType: 'blob'
  })
}


// 服务类型批量导入模板下载
export function importCustomerServiceTypeBatchTemplate() {
  return request({
    url: '/web/customerService/type/importCustomerServiceTypeBatchTemplate',
    method: 'post',
    responseType: 'blob'
  })
}



export function selectUserByNameJH(data) {
    return request({
        url: '/web/system/user/selectUserByNameJH',
        method: 'post',
        data
    });
}

//巡更人员列表
export function userList(data) {
    return request({
        url: '/web/core/api/v1/grid/queryGridData',
        method: 'post',
        data
    });
}

export function callInviteApi(data) {
    return request({
      url: '/web/sipcall/invite',
      method: 'post',
      data
    });
  }

  export function callQueryInvitationApi(id) {
    return request({
      url: '/web/sipcall/invitation/' + id,
      method: 'get',
    });
  }

  export function callAnswerApi(data) {
    return request({
      url: '/web/sipcall/answer',
      method: 'post',
      data
    });
  }

  export function invitationDelete(id) {
    return request({
      url: '/web/sipcall/invitation/delete/'+id,
      method: 'get',
    });
  }
  export function callToggleVideoApi(targetId, enabled) {
    return request({
      url: '/web/sipcall/message/toggle_video?userId=' + targetId + '&enabled=' + (enabled ? "true" : "false"),
      method: 'get',
    });
  }

//审核注册信息
export function approveRegister(data) {
  return request({
    url: `app/v1/user/approveRegister`,
    method: 'post',
    data
  });
}

//把部门分配给其他部门(允许拨打辖区)
export function allocationDeptToOtherDept(data) {
  return request({
    url: `web/sipcall/allocationDeptToOtherDept`,
    method: 'post',
    data
  });
}
//把部门分配给其他部门(允许拨打辖区)
export function deleteDeptConfig(data) {
  return request({
    url: `web/sipcall/deleteDeptConfig/${data}`,
    method: 'post',
  });
}
//批量删除通讯录配置
export function deleteDeptConfigList(data) {
  return request({
    url: `web/sipcall/deleteDeptConfigList`,
    method: 'post',
    data
  });
}

// 获取辖区的通讯录权限配置
export function getAllocationAuthByDeptId(deptId) {
  return request({
    url: `/web/sipcall/getCustomDeptViewByDeptId?deptId=${deptId}`,
    method: 'get',
  });
}



export function getCustomDeptViewId(deptId) {
  return request({
    url: `/app/v1/dept/getCustomDeptViewId?deptId=${deptId}`,
    method: 'get',
  });
}
export function getCustomDeptViewIdV2(deptId) {
  return request({
    url: `/app/v1/dept/getCustomDeptViewIdV2?deptId=${deptId}`,
    method: 'get',
  });
}



// 批量保存通讯录权限
export function batchSaveAllocationAuth(data) {
  return request({
    url: `web/sipcall/batchSaveAllocationAuth`,
    method: 'post',
    data
  });
}

// 保存通讯录权限
export function saveAllocationAuth(data) {
  return request({
    url: `/web/sipcall/saveCustomDeptView`,
    method: 'post',
    data
  });
}

//获取当前被呼叫用户是否在线
export function userWhetherInitiatedPutIntoService(userId) {
    return request({
      url: `/web/sipcall/userWhetherInitiatedPutIntoService/${userId}`,
      method: 'post',
    });
  }

//记录通话中的用户, 拨打电话时调用
export function recordEstablishUser(userId) {
  return request({
    url: `/web/sipcall/recordEstablishUser/${userId}`,
    method: 'post',
  });
}

//记录通话中的用户, 拨打电话时调用
export function removeEstablishUser() {
  return request({
    url: `/web/sipcall/removeEstablishUser/`,
    method: 'post',
  });
}



// 用户登录设备详情
export function viewUser(id) {
  return request({
      url: '/web/system/user/viewUser/' + id,
      method: 'get',
  });
}
export function deleteFace(id) {
  return request({
      url: '/app/v1/user/face/delete/' + id,
      method: 'get',
  });
}

// 用户信息详情
export function examineUser(id) {
  return request({
      url: '/web/system/user/examineUser/' + id,
      method: 'get',
  });
}



// 人员饼图接口
export function selectPersonnelTypeCount(data) {
  return request({
      url: '/web/dailyCount/selectPersonnelTypeCount',
      method: 'post',
      data
  });
}

// 用户身份饼图接口
export function selectXyBikeCategoryV2Count(data) {
  return request({
      url: '/web/dailyCount/selectXyBikeCategoryV2Count',
      method: 'post',
      data
  });
}
// 掌握技能饼图接口
export function selectSkillTypeCount(data) {
  return request({
      url: '/web/dailyCount/selectSkillTypeCount',
      method: 'post',
      data
  });
}
// 掌握装备饼图接口
export function selectEquipmentCount(data) {
  return request({
      url: '/web/dailyCount/selectEquipmentCount',
      method: 'post',
      data
  });
}

// 人员趋势图
export function selectMembershipGraph(data) {
  return request({
      url: '/web/dailyCount/selectMembershipGraph',
      method: 'post',
      data
  });
}

// 上下线图表
export function selectOnOffGraph(data) {
  return request({
    url: '/web/onOffCount/graph',
    method: 'post',
    data
  });
}















// 删除登录设备
export function commandClearUser(data) {
  return request({
    url: `/web/dept/commandClearUser`,
    method: 'post',
    data
  });
}


export function getCalculateDeploymentRate(data) {
  return request({
    url: `/web/system/personnel/getCalculateDeploymentRate`,
    method: 'post',
    data
  });
}
export function selectDeptPatrolDetails(data) {
  return request({
    url: `/web/system/personnel/selectDeptPatrolDetails`,
    method: 'post',
    data
  });
}
export function PatrolLogExportExcelDeptV2(data){
  return request({
    url: '/web/system/personnel/PatrolLogExportExcelDeptV2',
    method: 'POST',
    responseType: 'blob',
    data: data
  })
}

export function importPositionEmployeeConfig() {
  return request({
    url: '/web/system/personnel/importPositionEmployeeConfig',
    method: 'post',
    responseType: 'blob'
  });
}
