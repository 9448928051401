import Vue from 'vue';
import Cookies from 'js-cookie';
import '@/utils/dialog'
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets
import { getParentDeptName } from '@/api/modules/overview';

import Element from 'element-ui';
import './styles/element-variables.scss';
Vue.use(Element, { zIndex: 1000 })

import '@/styles/index.scss'; // global css

import App from './App';
import store from './store';
import router from './router';
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
import i18n from './lang'; // internationalization
import './icons'; // icon
import './router/permission'; // permission control
import './utils/index';
import * as filters from './filters'; // global filters
import VueAMap from 'vue-amap';
import scrollText from "@/components/scrollText.vue";
Vue.component('scrollText', scrollText);
import exportExcels from './api/component/exportExcels.js';
Vue.use(exportExcels)
import htmlToPdf from './api/component/htmlToPdf';
Vue.use(htmlToPdf)
Vue.use(VueAMap);

import VueBus from 'vue-bus';
Vue.use(VueBus);

import VxeUITable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VxeUITable)






// 默认配置写法

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

let tracesSampleRate = 1;
const node_env = process.env["NODE_ENV"];
const app_version = process.env["VUE_APP_VERSION"];
if (node_env === "production") {
  tracesSampleRate = 0.8
}
// Sentry.init({
//   Vue: Vue,
//   environment: node_env,
//   dsn: "https://bef6fdb2a1a243329fc2ac9378b2f268@sentry.xiyoubike.cn/7",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router)
//     }),
//   ],
//   tracesSampleRate: tracesSampleRate,
//   release: node_env + "@" + app_version,
// });

import { Button, Switch, NavBar, Col, Row, Field
  , Cell, CellGroup, Tag, Radio, RadioGroup, Checkbox
  , CheckboxGroup, Cascader, Popup, Toast,Popover,Icon,Badge,CountDown ,Picker,ActionSheet,Area,DatetimePicker,List     } from 'vant';
Vue.use(Switch);
Vue.use(ActionSheet);
Vue.use(Popover);
Vue.use(Button);
Vue.use(NavBar);
Vue.use(Col);
Vue.use(Row);
Vue.use(Field);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Cascader);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(Badge);
Vue.use(CountDown);
Vue.use(Picker);
Vue.use(Area);
Vue.use(DatetimePicker);
Vue.use(List);
VueAMap.initAMapApiLoader({
  key: 'f4d1deaac2129dd7b243af71fdafb9df',//f4d1deaac2129dd7b243af71fdafb9df
  plugin: ['Autocomplete', 'PlaceSearch', 'Scale', 'OverView', 'ToolBar', 'MapType', 'PolyEditor', 'AMap.CircleEditor', 'MouseTool', 'Scale', 'Geocoder'],
  v: '1.4.4'
});


import '@/views/overviewPro/js/directive';
import {fitChartSize} from '@/views/overviewPro/js/dataUtil.js'
import 'vue-orgchart/dist/style.min.css'

Vue.prototype.fitChartFont = fitChartSize;


import calc from './utils/calculation';
Vue.prototype.calc = calc;

import _ from 'lodash'
Vue.prototype._ = _

// echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

// 图片展示组件
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

// moment
import moment from 'moment';
Vue.prototype.$moment = moment;

// vue-quill-editor文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

// lodash
// import _ from 'lodash'
// Vue.prototype._ = _

// import XEUtils from 'xe-utils';
// import VXEUtils from 'vxe-utils';
// import XEAjax from 'xe-ajax';
// Vue.use(XEAjax);
// Vue.use(VXEUtils, XEUtils, { mounts: ['cookie', 'browse', 'locat'] });

// import VXETable from 'vxe-table';
// import 'vxe-table/lib/index.css';
// Vue.use(VXETable);

// import VueElementExtends from 'vue-element-extends';
// import 'vue-element-extends/lib/index.css';
// Vue.use(VueElementExtends);

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
});

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;
const preventReClick = Vue.directive('preventReClick', {
  inserted: function (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 10000)
      }
    })
  }
});

Vue.directive('truncate', {
  bind(el, binding, vnode) {
    el._originalText = el.innerText || el.textContent; // 存储原始文本
    truncateText(el);
  }
});
function truncateText(el) {
  if (el._originalText.length > 18) {
    let truncatedText = el._originalText.substring(0, 18) + '...'; // 截断文本
    el.innerText = truncatedText; // 更新元素的文本内容
    el.setAttribute('title', el._originalText); // 设置title属性为原始文本
  }
}


Vue.directive('dynamic-title', {
  inserted: function (el, binding) {
    updateTitle(el, binding);
  },
  componentUpdated: function (el, binding) { // 组件更新后重新设置title
    updateTitle(el, binding);
  }
});

function updateTitle(el, binding) {
  //暂时不使用 数据加载慢
  return;
  if (binding.value.column.label === "所属辖区") {
    let id = binding.value.row.deptId || binding.value.row.sysDeptId;
    getParentDeptName(id).then(res => {
      el.title = res.data;
    }).catch(error => {
      console.log("Error fetching parent department name:", error);
      // Handle error or set a default/fallback title if needed
      el.title = "信息获取失败";
    });
  }
}



new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App),
  updated() {
    // 查找所有使用了truncate指令的元素
    const elements = this.$el.querySelectorAll('[v-truncate]');
    elements.forEach(el => {
      truncateText(el);
    });
  }
});

Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: 'success' })
}
// 定义全局的 代码
Vue.mixin({
  data(){
    return{
    }
  },
  computed: {
  },
  methods: {
    queryGridDataV2(){
      return "/web/core/api/v2/grid/queryGridDataV2"
    },

    Authority() {
      return localStorage.getItem('Authority')// 1: 所有权限 2: 查看权限
    }   ,
    //直接下载文件  O(∩_∩)O哈哈~金子
    downloadQiNiuFile(file) {
      if (file){
        let url = this.convertToHttps(file.fileUrl);
        fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.blob();
          })
          .then(blob => {
            const urlObject = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = urlObject;
            link.download = file.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(urlObject);
          })
          .catch(error => {
            console.error('Error downloading file:', error);
          });
      }

    },
    convertToHttps(url) {
      if (url.indexOf('http://') === 0) {
        return url.replace('http://', 'https://');
      }
      return url;
    },
  },
});
